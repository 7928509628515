import request from "../utils/request";

class universityService {
    static createUniversity(data) {
        return request({
          url: "/users/formUniversity",
          method: "post",
          timeout: 60000,
          data,
        });
      }
      static getUniversity(query = {}) {
        return request({
          url: "/user/university",
          method: "get",
          timeout: 60000,
          params: query
        });
      }
      static getUniversityBySlug(query = {}) {
        return request({
          url: "/university/slug/"+ query.slug,
          method: "get",
          timeout: 60000,
          
        });
      }
      static getVacantesForUniversity(query = {}) {
        return request({
          url: "/university/vacantes/"+ query.slug,
          method: "get",
          timeout: 60000,
          
        });
      }
      static getdataUniversityBySlug(query = {}) {
        return request({
          url: "/slug/"+ query.slug,
          method: "get",
          timeout: 60000,
          
        });
      }
}
export default universityService;