<template>
    <div class="g1">
      <div class="container">
        <div class="c-up">
          <div class="container-information">
            <p class="text-front" style="font-family: Poppins;">Genera más oportunidades para estudiantes y profesionales
            </p>
          </div>
        </div>
        <div class="J-tab-container">
          <div class="J-tab-header">
            <div class="J-tab-nav">
              <div class="J-tab-nav-item" v-for="(tab, i) in tabs" :key="i + 1" :class="{ active: tab.checked }">
                <a href="javascript:void(0)" @click="activeTabs(i)" class="J-tab-nav-link">{{ i + 1 }}</a>
                <div class="J-tab-nav-text">
                  <p>{{ tab.title }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="J-tab-body">
            <!-- Datos Representante Empresarial -->
            <div v-show="tabs[0].active">
              <form @submit.prevent="activeTabs(1)" class="row J-flex center">
                <!--Nombre Representante Empresa-->
                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <input type="text" v-model="state.responsableUniversity" class="form-control" id="responsableUniversity"
                      required placeholder="Nombre Completo del Contacto">
                    <label for="floatingInput">Nombre Completo del Contacto</label>
                  </div>
                </div>
                <!--Telefono Contacto Empresa-->
                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <input type="tel" v-model="state.phoneUniversity" class="form-control" id="phoneUniversity" required
                      @keypress="onlyNumber" placeholder="Télefono de Contacto">
                    <label for="floatingInput">Télefono de Contacto</label>
                  </div>
                </div>
                <!--Posición Representante -->
                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <input type="text" v-model="state.positionUniversity" class="form-control" id="positionUniversity"
                      required placeholder="Posición dentro de la universidad">
                    <label for="floatingInput">Posición dentro de la universidad</label>
                  </div>
                </div>
                <!--Correo Empresarial-->
                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <input type="email" v-model="state.emailUniversity" class="form-control" id="emailUniversity" required
                      placeholder="Correo institucional de contacto">
                    <label for="floatingInput">Correo institucional de contacto</label>
                  </div>
  
                </div>
                <!--Tenemos state.emailCompany y statecompany (diferencia no se cual sea hasta el momento)-->
                <br />
                <!--Botones siguiente y atras-->
                <div class="J-flex between mt-5">
                  <div class="col-4 J-flex start">
                    <button class="J-btn J-btn-line" type="button" @click="activeTabs(0)">
                      Atras
                    </button>
                  </div>
                  <!--Boton siguiente funciona hasta la validación de los campos-->
                  <div class="col-4 J-flex end">
                    <button class="J-btn J-btn-secondary">Siguiente</button>
                  </div>
                </div>
              </form>
            </div>
            <!--Datos Acerca de la universidad-->
            <div v-show="tabs[1].active">
              <form class="row J-flex center" @submit.prevent="activeTabs(2)">
                <!--Nombre Comercial de la empresa-->
                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <input type="text" v-model="state.nameUniversity" class="form-control" id="nameUniversity" required
                      placeholder="Nombre de la institución rectora">
                    <label for="floatingInput">Nombre de la Institución Rectora</label>
                  </div>
                </div>
                <!--Abreviación de la Universidad -->
                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <input type="text" v-model="state.abbreviationUniversity" class="form-control" id="abbreviationUniversity" required
                      placeholder="Abreviación de la institución rectora">
                    <label for="floatingInput">Abreviación de la Institución Rectora</label>
                  </div>
                </div>
                <!--División Academica-->
                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <input type="text" v-model="state.academyDivision" class="form-control" id="academyDivision"
                      required placeholder="División Académica">
                    <label for="floatingInput">División Académica</label>
                  </div>
                </div>
                <!--División Academica-->
                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <input type="text" v-model="state.campus" class="form-control" id="campus"
                      required placeholder="Campus">
                    <label for="floatingInput">Campus</label>
                  </div>
                </div>
                <!--Sitio Web o LinkedIn-->
                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <input type="text" v-model="state.sitioWeb" class="form-control" id="urlInput" required
                      placeholder="Sitio Web">
                    <label for="floatingInput">URL de Sitio Web</label>
                  </div>
                </div>
                <!--Entidad Federativa-->
                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <select class="form-select" id="floatingSelect" aria-label="Estados"
                      v-model="state.federalentityUniversity" required>
                      <option value="" selected disabled>
                        Selecciona una opción
                      </option>
                      <option value="Aguascalientes">Aguascalientes</option>
                      <option value="Baja California">Baja California</option>
                      <option value="Baja California Sur">Baja California Sur</option>
                      <option value="Campeche">Campeche</option>
                      <option value="Chiapas">Chiapas</option>
                      <option value="Chihuahua">Chihuahua</option>
                      <option value="CDMX">Ciudad de México</option>
                      <option value="Coahuila">Coahuila</option>
                      <option value="Colima">Colima</option>
                      <option value="Durango">Durango</option>
                      <option value="Estado de México">Estado de México</option>
                      <option value="Guanajuato">Guanajuato</option>
                      <option value="Guerrero">Guerrero</option>
                      <option value="Hidalgo">Hidalgo</option>
                      <option value="Jalisco">Jalisco</option>
                      <option value="Michoacán">Michoacán</option>
                      <option value="Morelos">Morelos</option>
                      <option value="Nayarit">Nayarit</option>
                      <option value="Nuevo León">Nuevo León</option>
                      <option value="Oaxaca">Oaxaca</option>
                      <option value="Puebla">Puebla</option>
                      <option value="Querétaro">Querétaro</option>
                      <option value="Quintana Roo">Quintana Roo</option>
                      <option value="San Luis Potosí">San Luis Potosí</option>
                      <option value="Sinaloa">Sinaloa</option>
                      <option value="Sonora">Sonora</option>
                      <option value="Tabasco">Tabasco</option>
                      <option value="Tamaulipas">Tamaulipas</option>
                      <option value="Tlaxcala">Tlaxcala</option>
                      <option value="Veracruz">Veracruz</option>
                      <option value="Yucatán">Yucatán</option>
                      <option value="Zacatecas">Zacatecas</option>
                    </select>
                    <label for="floatingSelect">Entidad Federativa</label>
                  </div>
                </div>
                <!--Botones Atras y Siguiente funciona al validar los campos llenos-->
                <div class="J-flex between mt-5">
                  <div class="col-4 J-flex start">
                    <button class="J-btn J-btn-line" type="button" @click="activeTabs(0)">
                      Atras
                    </button>
                  </div>
                  <div class="col-4 J-flex end">
                    <button class="J-btn J-btn-secondary">Siguiente</button>
                  </div>
                </div>
              </form>
            </div>
            <!--Número de empleados-->
            <div v-show="tabs[2].active">
              <form class="row J-flex center" @submit.prevent="activeTabs(3)">
                <!--Radio buttons número de empleados-->
                <div class="col"
                  style="display:flex; justify-content:center; align-content: center; flex-direction: column;">
                  <div class="container-text-note-a" style="display:flex; justify-content:center; align-content: center;">
                    <p class="text-note-a">Matrícula de alumnos</p>
                  </div>
                  <div style="display:flex; justify-content:center; align-content: center; flex-direction: row;">
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios1"
                        v-model="state.studentNumber" value="1-10" checked />
                      <label class="form-check-label" for="gridRadios1">
                        Menor a 100
                      </label>
                    </div>
                    <!--NO TIENE INPUT O VALIDATION DENTRO DE LOS METODOS, SOLO INPUT CLASS-->
                    &nbsp; &nbsp; &nbsp;
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios2"
                        v-model="state.studentNumber" value="10-100" />
                      <label class="form-check-label" for="gridRadios2">
                        100 - 500
                      </label>
                    </div>
                    &nbsp; &nbsp; &nbsp;
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios3"
                        v-model="state.studentNumber" value="100-500" />
                      <label class="form-check-label" for="gridRadios3">
                        500 - 1000
                      </label>
                    </div>
                    &nbsp; &nbsp; &nbsp;
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios4"
                        v-model="state.studentNumber" value="+500" />
                      <label class="form-check-label" for="gridRadios4">
                        +1000
                      </label>
                    </div>
                  </div>
                </div>
                <!--Selección de sector empresarial-->
                <div class="col-md-6" style="display:flex; justify-content: center;">
                  <div class="form-floating">
                    <!--V-MODEL ES CORRECTO-->
                    <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                      v-model="state.careersUniversity" required>
                      <option value="" selected disabled>
                        Selecciona una opción
                      </option>
                      <option v-for="(item, i) in dataCarreras" :key="i + 1" :value="item.name"> {{ item.name }}
                      </option>
                    </select>
                    <label for="floatingSelect">Carreras que ofrece</label>
                  </div>
                </div>
                <!--Botones atras y siguiente (Misma funcionalidad)-->
                <div class="J-flex between mt-5">
                  <div class="col-4 J-flex start">
                    <button class="J-btn J-btn-line" type="button" @click="activeTabs(1)">
                      Atras
                    </button>
                  </div>
                  <div class="col-4 J-flex end">
                    <button class="J-btn J-btn-secondary">Siguiente</button>
                  </div>
                </div>
              </form>
            </div>
            <!--Documentos Empresa Validación-->
            <div v-show="tabs[3].active">
              <form class="row J-flex center" @submit.prevent="saveData()">
                <!--Casilla para subir documemnto-->
                <div class="new" style="display:flex; flex-direction: column; justify-content: center; align-items: center; margin-left: 20px;">
                    <div class="container-text-note-a" style="display:flex; justify-content:center; align-content: center;">
                        <p style="font-size: 25px; font-weight: bold;">Selecciona Servicios de Colaboración</p>
                    </div>
                    <div style="display:flex; justify-content:center; align-content: center; flex-direction: row;">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="gridCheckbox1"
                            v-model="state.universityBag" value="BolsaUniversitaria" />
                            <label class="form-check-label" for="gridCheckbox1">
                                Bolsa Universitaria
                            </label>
                        </div>
                        &nbsp; &nbsp; &nbsp;
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="gridCheckbox2"
                            v-model="state.universityStore" value="TiendaUniversitaria" />
                            <label class="form-check-label" for="gridCheckbox2">
                                Tienda Universitaria
                            </label>
                        </div>
                        &nbsp; &nbsp; &nbsp;
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="gridCheckbox3"
                            v-model="state.educationalOffer" value="OfertaEducativa" />
                            <label class="form-check-label" for="gridCheckbox3">
                                Oferta Educativa
                            </label>
                        </div>
                        &nbsp; &nbsp; &nbsp;
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="gridCheckbox4"
                            v-model="state.universityEvents" value="Eventos" />
                            <label class="form-check-label" for="gridCheckbox4">
                                Eventos
                            </label>
                        </div>
                    </div>

                </div>
                <div class="col-md-12 J-flex center mt-5">
                  <div class="form-check form-switch">
                    <input class="form-check-input" v-model="state.terms" type="checkbox" role="switch" id="terms"
                      required />
                    <a href="https://api.mundouniversitario.mx/uploads/files/Aviso_Privacidad.pdf"
                      class="form-check-label" for="terms" target="_blank" style="color: black;">AVISO DE PRIVACIDAD</a>
                  </div>
                </div>
                <div class="col-md-12 J-flex center mt-3">
                  <div class="form-check form-switch">
                    <input class="form-check-input" v-model="state.useAcept" type="checkbox" role="switch" id="terms"
                      required />
                    <a href="https://api.mundouniversitario.mx/uploads/files/Acuerdo_Usuario.pdf" class="form-check-label"
                      for="terms" target="_blank" style="color: black;">ACUERDO DE USO</a>
                  </div>
                </div>
                <!--Misma función de botones atras-->
                <div class="J-flex between mt-5">
                  <div class="col-4 J-flex start">
                    <button class="J-btn J-btn-line" type="button" @click="activeTabs(4)">
                      Atras
                    </button>
                  </div>
                  <!--Boton Enviar falta cambiar el Back-->
                  <div class="col-4 J-flex end">
                    <button type="submit" :disabled="response.loading" class="J-btn J-btn-secondary"> Enviar
                      <div class="spinner-border text-primary" role="status" v-if="response.loading">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { computed, reactive } from "vue";
  import useValidate from "@vuelidate/core";

  import { required, minLength, maxLength, helpers } from "@vuelidate/validators";
  import { CompanyService } from "@/services";
  import universityService from "../services/university.service";
  
  export default {
    setup() { //en orden de aparición
      const state = reactive({
        responsableUniversity: "",
        phoneUniversity: "",
        emailUniversity: "",
        positionUniversity: "",
        nameUniversity: "",
        abbreviationUniversity: "",
        academyDivision: "",
        campus: "",
        studentNumber: "",
        sitioWeb: "",
        federalentityUniversity: "",
        careersUniversity: "",
        universityBag: false,
        universityStore: false,
        educationalOffer: false,
        universityEvents: false,
        terms: false,
        useAcept: false,
      });
      const rules = computed(() => {
        return {
          responsableUniversity: {
            required: helpers.withMessage("Por favor, llene el campo.", required),
          },
          emailUniversity: {
            required: helpers.withMessage("Por favor, llene el campo.", required),
          },
          phoneUniversity: {
            required: helpers.withMessage("Por favor, llene el campo.", required),
            minLength: helpers.withMessage(
              "El número debe de tener mínimo 10 dígitos.",
              minLength(10)
            ),
            maxLength: helpers.withMessage(
              "El número debe tener máximo 12 dígitos.",
              maxLength(12)
            ),
          },
          positionUniversity: {
            required: helpers.withMessage("Por favor, llene el campo.", required),
          },
        };
      });
      const v$ = useValidate(rules, state);
      return {
        state,
        v$,
      };
    },
    data() {
      return {
        tabs: [
          {
            title: "Datos de acceso y validación",
            active: true,
            checked: true,
          },
          {
            title: "Acerca de la universidad",
            active: false,
            checked: false,
          },
          {
            title: "Número de estudiantes",
            active: false,
            checked: false,
          },
          {
            title: "Servicios de Colaboración",
            active: false,
            checked: false,
          },
        ],
        tempSkill: "",
        skills: [],
        dataCarreras: [
            { "name": "Ciencias Exactas y Naturales" },
            { "name": "Ingeniería y Tecnología" },
            { "name": "Ciencias Sociales" },
            { "name": "Salud" },
            { "name": "Humanidades y Artes" },
            { "name": "Administración y Negocios" },
            { "name": "Educación" },
            { "name": "Ciencias Agropecuarias" },
            { "name": "Ciencias de la Comunicación" },
            { "name": "Derecho" },
            { "name": "Arquitectura y Diseño" },
        ],
        response: {
          error: false,
          loading: false,
          success: false,
          message: "",
        },
      };
    },
    methods: {
      activeTabs(i) {
        this.tabs.forEach((el, index) => {
          if (i === index) {
            el.active = true;
            el.checked = true;
          } else {
            el.active = false;
          }
        });
      },
      async saveData() {
        try {
          this.response.loading = true;
          const resp = await universityService.createUniversity(this.state);
          const { data } = resp.data;
          localStorage.setItem("accessToken", resp.data.accessToken);
          localStorage.setItem("user", JSON.stringify(resp.data.data.user));
          window.location.href = "/universidad/perfil";
          this.response.loading = false;
        } catch (e) {
          this.response.loading = false;
          this.$swal({
            position: "top-center",
            icon: "error",
            text: e.resp,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      },
      alertUse(positionAlert, title, iconalert) {
        this.$swal({
          position: positionAlert,
          icon: iconalert,
          timer: 5000,
          html: "<center>" + title + "</center>",
          confirmButtonText: 'Continuar <i class="fa fa-thumbs-up"></i> ',
          confirmButtonAriaLabel: "Thumbs up, great!",
        });
      },
      onlyNumber($event) {
        let keyCode = $event.keyCode ? $event.keyCode : $event.which;
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 44) {
          // 46 is dot
          $event.preventDefault();
        }
      },
    },
  };
  </script>
  
  <style land="scss" scoped>
  @import "../styles/formularioempresa.scss";
  </style>
  